<template>
  <v-app>

    <v-navigation-drawer v-if="isMobile" v-model="drawer" style="width: 80vw; background-color: #134f32;" temporary>
      <div
        style="margin-top: 100px; display: flex; justify-content: center; align-items: center; padding-inline: 40px; flex-direction: column;">
        <button style="width: 100%; text-align: center; color: white; margin-top: 20px; font-size: 20px;">HOME</button>
        <button style="width: 100%; text-align: center; color: white; margin-top: 30px; font-size: 20px;">ABOUT
          US</button>
        <button style="width: 100%; text-align: center; color: white; margin-top: 30px; font-size: 20px;">BLOG</button>
        <button style="width: 100%; text-align: center; color: white; margin-top: 30px; font-size: 20px;">CONTACT</button>
      </div>
    </v-navigation-drawer>

    <v-app-bar v-if="!isMobile" density="compact" elevation="1" scroll-behavior="hide"
      style="padding: 10px; padding-inline: 200px;" >
      <img style="width: 40px; margin-bottom: 8px;" src="@/assets/logo.svg" alt="Logo">
      <h2 style="margin-left: 8px;">Clyme</h2>
      <v-spacer></v-spacer>
      <button @click="homeClicked" style="margin-right: 50px; font-size: 14px; font-weight: bold;">HOME</button>
      <button @click="homeClicked" style="margin-right: 50px; font-size: 14px; font-weight: bold;">ABOUT</button>
      <button @click="homeClicked" style="margin-right: 50px; font-size: 14px; font-weight: bold;">BLOG</button>
      <button @click="homeClicked" style="font-size: 14px; font-weight: bold;">CONTACT</button>
    </v-app-bar>

    <v-app-bar v-else density="compact"  elevation="1"
      style="padding: 0px;">
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <img style="width: 30px; vertical-align: middle; margin-bottom: 8px; margin-right: 4px; margin-left: -60px;"
        src="@/assets/logo.svg" alt="Logo">
      <p style="font-weight: bold; margin-left: 4px;">Clyme</p>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>


  </v-app>
</template>


<script>
// import MobileView from '@/views/MobileView.vue';
// import DesktopView from '@/views/DesktopView.vue';
import Lenis from '@studio-freight/lenis'

const lenis = new Lenis()
lenis.on('scroll', (e) => {
  console.log(e)
})
function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}
requestAnimationFrame(raf)

export default {
  name: 'App',
  components: {
    // MobileView,
    // DesktopView
  },
  data() {
    return {
      isMobile: window.innerWidth < 1000,
      drawer: false,
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  computed: {
    isAndroidChrome() {
      return /Chrome/.test(navigator.userAgent) && /Android/.test(navigator.userAgent);
    },
    isiOSChrome() {
      return /CriOS/.test(navigator.userAgent) && /iPhone|iPad|iPod/.test(navigator.userAgent);
    },
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 1000; // Update the flag on window resize
    },
    homeClicked() {
      this.$router.push('/')
      const targetElement = document.getElementById('home');
      if (targetElement) {
        if (this.isAndroidChrome) {
          targetElement.scrollIntoView({ behavior: 'auto' });
        } else {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
      this.drawer = !this.drawer
    },
    contactClicked() {
      this.$router.push('/contact')
      this.drawer = !this.drawer
    },
    scrollToServices() {

      this.$router.push('/')

      if (this.isMobile) {
        this.drawer = !this.drawer
        setTimeout(() => {
          const targetElement = document.getElementById('services-mobile');
          console.log(targetElement)
          if (targetElement) {
            if (this.isAndroidChrome) {
              targetElement.scrollIntoView({ behavior: 'auto' });
            } else {
              targetElement.scrollIntoView({ behavior: 'smooth' });
            }
          }
        }, 2)
      } else {
        setTimeout(() => {
          const targetElement = document.getElementById('services');
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0)
      }

    },
    careersClicked() {

    }
  },
}
</script>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
