<template>
  <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
    <div class="mission-section">
      <p style="font-size: 20px; color: rgb(220, 220, 220);">OUR MISSION</p>
      <h1 style="font-size: 40px; line-height: 1.2; margin-top: 40px; padding-inline: 30px;">
        Transforming Sustainability<br>through<br>Innovation
      </h1>
    </div>
    <div
      style="background-color: white; box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); margin-top: -70px; width: 80%; padding: 20px; z-index: 2;">
      <h1 style="color: #539407; font-size: 18px;">What drives us</h1>
      <p style="font-size: 18px;">is a passion for pioneering eco-friendly solutions that safeguard our planet's future
      </p>
    </div>
    <div style="display: flex; margin-top: -50px;">
      <div
        style="display: flex; flex-direction: column; justify-content: center; align-items: center; padding-inline: 10%;">
        <h1 style="font-size: 20px; margin-top: 100px;">About Us</h1>
        <p style="margin-top: 20px; text-align: center; font-size: 18px; margin-bottom: 70px;">
          Clyme is a forward-thinking climate tech company committed to addressing climate change in Africa through ground
          breaking technology.
        </p>
      </div>
    </div>

    <div
      style="display: flex; flex-direction: column; justify-content: center; align-items: center; padding-inline: 10%; background-color: #E8F5E9;">
      <h1 style="font-size: 20px; margin-top: 50px;">What we do</h1>
      <p style="margin-top: 20px; text-align: center; font-size: 18px; margin-bottom: 70px;">
        Through advocacy campaigns and educational programs, we raise awareness about pressing environmental issues and
        empower
        communities to take meaningful action for a healthier planet. We also continuously explore and develop
        cutting-edge technologies
        that drive sustainability across industries, from green infrastructure to eco-friendly practices.
      </p>
    </div>

    <div
      style="background-color: #f9fffc; display: flex; flex-direction: column; width: 100%; height: 50vh; align-items: center; justify-content: center; padding-inline: 30px;">
      <h1 style=" font-size: 18px;">Want to help?</h1>
      <p style="margin-top: 20px; font-size: 18px;">Whether you're passionate about renewable energy, environmental
        advocacy, or community-driven solutions, there are numerous ways you can get involved.
        From volunteering opportunities to spreading awareness and supporting our projects, your involvement can
        make a real difference. </p>
      <button
        style="padding: 10px 20px; margin-top: 20px; border: none; border-radius: 30px; background-color: #257850; color: white; font-size: 18px;" @click="openEmailInNewTab">
        Get in touch
      </button>
    </div>

    <div
      style="background-color: #222724; display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 20px;">
      <p style="color: white; margin-top: 30px;">Have a question, suggestion, or just want to say hello? We'd love to hear
        from you!</p>
      <p style="color: white; margin-top: 40px;">Connect With Us</p>
      <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
        <img style="width: 20px; vertical-align: middle;" src="@/assets/mail.png" alt="Logo">
        <img style="width: 20px; vertical-align: middle; margin-left: 20px;" src="@/assets/twitter.png" alt="Logo">
        <img style="width: 20px; vertical-align: middle; margin-left: 20px;" src="@/assets/linkedin.svg" alt="Logo">
        <img style="width: 20px; vertical-align: middle; margin-left: 20px;" src="@/assets/instagram.svg" alt="Logo">
      </div>

      <p style="margin-top: 50px; font-size: 14px; padding-bottom: 20px; color: white;">©{{ getCurrentYear() }} CLYME.
        ALL RIGHTS RESERVED</p>


    </div>


  </div>
</template>

<script>
export default {

  methods: {
    getCurrentYear() {
      const now = new Date();
      return now.getFullYear();
    },
    openEmailInNewTab() {
      const mailtoAnchor = document.createElement('a');
      mailtoAnchor.href = "mailto:hey.clyme@gmail.com";
      mailtoAnchor.target = '_blank';
      mailtoAnchor.click()
    },
  }

}
</script>

<style>
</style>