<template>
  <div class="home">
    <div v-if="isMobile"> 
      <MobileView></MobileView>
    </div>
    <div v-else>
      <DesktopView></DesktopView>
    </div>
  </div>
</template>

<script>
import DesktopView from './DesktopView.vue';
import MobileView from './MobileView.vue';

export default {
  name: 'HomeView',
  components: {
    DesktopView,
    MobileView
  },
  data() {
    return {
      isMobile: window.innerWidth < 1000,
      drawer: false,
    };
  },
}
</script>
