<template>
    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
        <div class="mission-section">
            <p style="font-size: 25px;">OUR MISSION</p>
            <h1 style="font-size: 60px; line-height: 1.2; margin-top: 40px;">
                Transforming Sustainability<br>through<br>Innovation
            </h1>
        </div>
        <div
            style="background-color: white; box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); margin-top: -50px; width: 60%; padding: 20px; z-index: 2;">
            <h1 style="color: #539407; font-size: 20px;">What drives us</h1>
            <p>is a passion for pioneering eco-friendly solutions that safeguard our planet's future.</p>
        </div>
        <div style="display: flex; margin-top: -50px;">
            <div
                style="display: flex; flex-direction: column; justify-content: center; align-items: start; padding-inline: 10%;">
                <h1 style="font-size: 30px;">ABOUT US</h1>
                <p style="margin-top: 30px; text-align: start; font-size: 28px;">
                    Clyme is a forward-thinking climate tech company committed to addressing climate change in Africa
                    through ground breaking technology.
                </p>
            </div>
            <img src="@/assets/flower.png" alt="flower" style="">
        </div>

        <div style="display: flex;">
            <img src="@/assets/mountain.svg" alt="mountain" style="">
            <div
                style="display: flex; flex-direction: column; justify-content: center; align-items: start; padding-inline: 10%; background-color: #E8F5E9;">
                <h1 style="font-size: 30px;">WHAT WE DO</h1>
                <p style="margin-top: 30px; text-align: start; font-size: 28px;">
                    Through advocacy campaigns and educational programs, we raise awareness about pressing environmental
                    issues and empower communities to take meaningful action for a healthier planet.
                    We also continuously explore and develop cutting-edge technologies that drive sustainability across
                    industries, from green infrastructure to eco-friendly practices.
                </p>
            </div>
        </div>


        <div
            style="background-color: #f9fffc; display: flex; flex-direction: column; width: 100%; height: 50vh; align-items: center; justify-content: center; padding-inline: 300px;">
            <h1 style=" margin-top: 30px;">Want to help?</h1>
            <p style="margin-top: 40px; font-size: 28px;">Whether you're passionate about renewable energy, environmental
                advocacy, or community-driven solutions, there are numerous ways you can get involved.
                From volunteering opportunities to spreading awareness and supporting our projects, your involvement can
                make a real difference. </p>
            <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
                <img style="width: 20px; vertical-align: middle;" src="@/assets/mail.png" alt="Logo">
                <img style="width: 20px; vertical-align: middle; margin-left: 20px;" src="@/assets/twitter.png" alt="Logo">
                <img style="width: 20px; vertical-align: middle; margin-left: 20px;" src="@/assets/linkedin.svg" alt="Logo">
                <img style="width: 20px; vertical-align: middle; margin-left: 20px;" src="@/assets/instagram.svg"
                    alt="Logo">
            </div>
            <button
                style="padding: 10px 20px; border: none; border-radius: 30px; background-color: #257850; color: white; font-size: 20px;"
                @click="openEmailInNewTab">
                Get in touch
            </button>
        </div>

        <div style="background-color: #222724; display: flex; flex-direction: column; width: 100%; align-items: start; justify-content: center; 
            padding-inline: 100px; padding-top: 50px; padding-bottom: 50px;">
            <div style="display: flex; align-items: center; justify-content: center; margin-top: 50px;">
                <img style="width: 40px; vertical-align: middle; margin-right: 4px; margin-bottom: 4px;"
                    src="@/assets/logo.svg" alt="Logo">
                <p style="font-weight: bold; margin-left: 4px; font-size: 28px; color: white;">Clyme</p>
            </div>
            <p style="color: white; font-size: 20px; margin-top: 20px;">Have a question, suggestion, or just want to say hello? We'd love to hear from you!</p>


            <div style="border: 0.4px solid white; width: 100%; margin-top: 50px;"></div>


            <div
                style="display: flex; justify-content: space-between; width: 100%; align-items: center; margin-top: 20px; margin-bottom: 30px;">
                <p style="font-size: 14px; color: white;">© {{ getCurrentYear() }} CLYME. ALL RIGHTS
                    RESERVED
                </p>
                <div>
                    <img style="width: 20px; vertical-align: middle;" src="@/assets/mail.png" alt="Logo">
                    <img style="width: 20px; vertical-align: middle; margin-left: 20px;" src="@/assets/twitter.png"
                        alt="Logo">
                    <img style="width: 20px; vertical-align: middle; margin-left: 20px;" src="@/assets/linkedin.svg"
                        alt="Logo">
                    <img style="width: 20px; vertical-align: middle; margin-left: 20px;" src="@/assets/instagram.svg"
                        alt="Logo">
                </div>

            </div>

        </div>


    </div>
</template>

<script>
export default {
    methods: {
        getCurrentYear() {
            const now = new Date();
            return now.getFullYear();
        },
        openEmailInNewTab() {
            const mailtoAnchor = document.createElement('a');
            mailtoAnchor.href = "mailto:hey.clyme@gmail.com";
            mailtoAnchor.target = '_blank';
            mailtoAnchor.click()
        },
    }
}
</script>

<style>
.mission-section {
    background-image: url('@/assets/green1.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 70vh;
    color: white;
    width: 100vw;
}
</style>

